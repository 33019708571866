import React from 'react';
import { useApi } from './useApi';
import { useParams } from 'react-router-dom';

import { Team } from './types';

interface Game {
  eventDate: string;
  eventTimestamp: number;
  fixtureId: number;
  league: {
    leagueId: number;
    name: string;
  };
  round: string;
  teamAway: {
    goals: number | null;
    teamId: number;
    teamName: string;
  };
  teamHome: {
    goals: number | null;
    teamId: number;
    teamName: string;
  };
  venue: string | null;
}

interface NewsItem {
  description?: string;
  title: string;
  url: string;
}

type ResponseGames = {
  next: Game[];
  previous: Game[];
};

export const PageTeam = ({ teams }: { teams: Team[] | null }) => {
  const { teamSlug } = useParams();
  const { data: games } = useApi<ResponseGames>(`/games/${teamSlug}`);
  const { data: newsEI } = useApi<NewsItem[]>(`/news/ei/${teamSlug}`);
  const { data: newsGE } = useApi<NewsItem[]>(`/news/ge/${teamSlug}`);
  const team = teams?.find((team) => team.slug === teamSlug);

  const nextGame = games?.next[0];

  return (
    <div className="App">
      <h1>{team?.name}</h1>
      <br />
      <br />
      {!!nextGame && (
        <>
          <h2>Próximo Jogo:</h2>
          <div>
            <small>
              {nextGame.league.name} - {nextGame.round}
            </small>
            <h3>
              {nextGame.teamHome.teamName} x {nextGame.teamAway.teamName}
            </h3>
            <small>
              {nextGame.eventDate} - {nextGame.venue}
            </small>
          </div>
        </>
      )}

      <br />
      <br />
      <h2>Jogos Anteriores:</h2>
      {games?.previous.map((game) => (
        <React.Fragment key={game.fixtureId}>
          <div>
            <small>
              {game.league.name} - {game.round}
            </small>
            <h3>
              {game.teamHome.teamName} x {game.teamAway.teamName}
            </h3>
            <small>
              {game.eventDate} - {game.venue}
            </small>
          </div>
          <hr />
        </React.Fragment>
      ))}

      <br />
      <br />
      <h2>Notícias Globo Esporte:</h2>
      {newsGE?.map((news) => {
        if (news.title) {
          return (
            <a key={news.url} href={news.url} target="_blank">
              <p>{news.title}</p>
              {news.description && <small>{news.description}</small>}
              <hr />
            </a>
          );
        }
      })}

      <br />
      <br />
      <h2>Notícias Esporte Interativo:</h2>
      {newsEI?.map((news) => {
        if (news.title) {
          return (
            <a key={news.url} href={news.url} target="_blank">
              <p>{news.title}</p>
              {news.description && <small>{news.description}</small>}
              <hr />
            </a>
          );
        }
      })}
    </div>
  );
};
