import { useState, useEffect } from 'react';

interface apiData<T> {
  isLoading: boolean;
  data: T | null;
}

export const useApi = <T>(path: string) => {
  const [data, setData] = useState<apiData<T>>({ isLoading: true, data: null });
  const url = `${process.env.REACT_APP_SITE_URL}/api${path}`;

  useEffect(() => {
    fetch(url, {
      headers: {
        'x-api-key': '8ba96386-29ea-4ef5-8074-7d84913d3fe2',
      },
    })
      .then((rawResponse) => {
        return rawResponse.json();
      })
      .then((jsonResponse: T) => {
        setData({
          isLoading: false,
          data: jsonResponse,
        });
      });
  }, []);

  return data;
};
