import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useApi } from './useApi';
import { Team } from './types';

import { PageIndex } from './pageIndex';
import { PageTeam } from './pageTeam';

function App() {
  const { data: teams } = useApi<Team[]>('/teams/all');

  return (
    <Router>
      <Switch>
        <Route path="/team/:teamSlug">
          <PageTeam teams={teams} />
        </Route>
        <Route path="/">
          <PageIndex teams={teams} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
