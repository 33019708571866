import React from 'react';
import { Link } from 'react-router-dom';

import { Team } from './types';

export const PageIndex = ({ teams }: { teams: Team[] | null }) => {
  return (
    <div className="App">
      <h1>Times:</h1>
      {teams?.map((team) => (
        <p key={team.team_id}>
          <Link to={`/team/${team.slug}`}>{team.name}</Link>
        </p>
      ))}
    </div>
  );
};
